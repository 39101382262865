
.product-cat-img-1{
   color:white;
    background-image: url('./images/blog1.jpg');
    width:250px;
    height:200px;
    align-items: center;
    text-align: center;
 }
 .product-cat-img-2{
    color:white;
    background-image: url('./images/blog2.jpg');
    width:250px;
    height:200px;
    align-items: center;
    text-align: center;
 }
 .product-cat-img-3{
    color:white;
    background-image: url('./images/blog3.jpg');
    width:250px;
    height:200px;
    align-items: center;
    text-align: center;
 }
 .product-cat-img-4{
    color:white;
    background-image: url('./images/blog4.jpg');
    width:250px;
    height:200px;
    align-items: center;
    text-align: center;
 }