.eruvaka-header{
  background: hsla(0, 0%, 0%, 0);
  backdrop-filter: blur(0.2rem);
}

.site-logo {
  width: 40px;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.img-resize {
    width: 100%;
 }
 
.BgImage0 {
  background-image: url(../src/images/0.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}
 
.BgImage1 {
  background-image: url(../src/images/1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}
 
.BgImage2 {
  background-image: url(../src/images/2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}
 
.BgImage3 {
  background-image: url(../src/images/3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}
 
.BgImage4 {
  background-image: url(../src/images/4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.eruvaka-footer{
  background-color: green;
}
.eruvaka-copyright{
  background-color: rgba(0, 0, 0, 0.2);
}